import { useState, useEffect } from 'react';
import { axiosEstimates } from '../../../utils';
import { useServices } from './useServices';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';

export default function useStatus(serviceId: number) {
  const [status, setStatus] = useState<'active' | 'inactive'>();
  const { setReloadData } = useServices();

  const handleStatus = (value: 'active' | 'inactive') => {
    CustomSwalAlert('Aviso', 'Desea cambiar el estado del servicio?', 'info', true, () => {
      setStatus(value);
    });
  };

  useEffect(() => {
    if (serviceId && status) {
      axiosEstimates
        .patch(`api/services/status/${serviceId}`, { status })
        .then(response => {
          setStatus(response.data.status);
          CustomSwalAlert('Enhorabuena', 'Éxito en el cambio de estado del servicio', 'success', false);
          setReloadData(true);
        })
        .catch(error => {
          console.error(error);
          CustomSwalAlert('Error', 'Error al cambiar el estado del servicio', 'error', false);
        });
    }
  }, [serviceId, status]);

  return { status, handleStatus };
}
