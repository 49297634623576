import { useForm } from 'react-hook-form';
import { useEffect, useState, useRef, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import ReactSignatureCanvas from 'react-signature-canvas';

// @Mui
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import LanguageDropdown from '../../components/Dropdown/LanguageMenu';

// @Components
import { TextForm } from '../../components';

// @Utils
import { axiosEstimates } from '../../utils/api';
import dataURLtoFile from '../../utils/DataURLToFile';

/**
 * @component
 * @subcategory Pages
 *
 * @see Route: "https://estimates.cmlgroupapps.com/approve"
 *
 * @description This component is used to approve a budget. It includes a form to input the full name and signature, and a button to submit the approval.
 *
 * #### Example
 * ```jsx
 * return(
 * <ApproveBudget />
 * )
 * ```
 *
 * @return {JSX.Element} The component returns a JSX.Element containing the budget approval form.
 * @author CML Exports Front-End Developers
 */
export function ApproveBudget() {
  const [token, setToken] = useState<string | null>(null);
  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(true);

  const mdAbove = useMediaQuery((theme: Theme) => theme.breakpoints.up(600));

  const signatureRef = useRef() as MutableRefObject<any>;

  const [searchParams] = useSearchParams();

  const { t } = useTranslation('common');

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { fullname: '' },
  });

  const validateToken = async (token: string) => {
    const toastId = toast.loading(t('toast.loading'));

    setLoading(true);

    try {
      await axiosEstimates.get('/invoice/validate-token', {
        headers: { 'x-invoice-token': token },
      });
      setValid(true);
    } catch (error) {
      setValid(false);
    }
    toast.dismiss(toastId);
    setLoading(false);
  };

  const reset = () => {
    setValue('fullname', '');
    signatureRef.current.clear();
  };

  const onSubmit = async (data: { fullname: string }) => {
    if (!token) {
      toast.error('Enlance dañado');
      return null;
    }
    if (data.fullname === '') {
      toast.error('El nombre es requerido');
      return null;
    }
    if (signatureRef.current.isEmpty()) {
      toast.error('La firma es requeridad');
      return null;
    }

    const imageUrl = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');

    const formData = new FormData();
    formData.append('fullname', data.fullname);
    formData.append('signature', dataURLtoFile(imageUrl, data.fullname), data.fullname + '.png');

    const toastId = toast.loading(t('general.sending'));

    try {
      const res = await axiosEstimates.post<{ data: { idBudget: number } }>('invoice/approve', formData, {
        headers: { 'x-invoice-token': token },
      });

      const idBudget = res.data.data.idBudget;

      await axiosEstimates.post('invoice/send/approve/' + idBudget, {});
      toast.dismiss(toastId);
      toast.success(t('budgetPage.quotationApproved'));
      setTimeout(() => {
        window.close();
      }, 500);
    } catch (error) {
      toast.dismiss(toastId);
      toast.success(t('budgetPage.quotationApproved'));
      setTimeout(() => {
        window.close();
      }, 500);
      // toast.error('El enlance expiró');
    }
  };

  useEffect(() => {
    setToken(searchParams.get('token'));
    validateToken(searchParams.get('token') || '');
  }, []);

  if (loading) return <Box />;

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
        <LanguageDropdown />
      </Box>

      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}
      >
        <Card sx={{ width: { sm: '28rem' }, zIndex: 1 }}>
          <CardContent sx={{ padding: 2 }}>
            <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box component='img' sx={{ width: 120 }} alt='cml' src={'/assets/400x246-CML-Exports.png'} />
            </Box>

            {valid ? (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                  <Typography variant='h6'>{t('general.budgetApproved')}</Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                    gap: 2,
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <TextForm control={control} name='fullname' label={t('general.fullname')} />
                  </Box>

                  <Box sx={{ background: 'white', border: '1px solid', borderRadius: '0.1rem', width: '100%' }}>
                    <ReactSignatureCanvas
                      penColor='black'
                      canvasProps={{ className: 'sigCanvas', width: mdAbove ? 384 : 239, height: 200 }}
                      backgroundColor='white'
                      ref={signatureRef}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    p: 2,
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Button sx={{ justifyContent: 'center' }} variant='contained' color='secondary' onClick={reset}>
                    {t('general.clear')}
                  </Button>

                  <Button sx={{ justifyContent: 'center' }} variant='contained' onClick={handleSubmit(onSubmit)}>
                    {t('general.approve')}
                  </Button>
                </Box>
              </>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                <Typography variant='h4' color='error'>
                  {t('general.urlExpired')}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
